// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Include the Rubik font from Google Fonts */

.sidebar {
  width: 215px;
  background-color: #ffffff;
  padding: 15px;
  height: 100vh;
  position: fixed;
  font-family: 'Rubik', sans-serif;
  font-size: 13px; /* Set the font size to 13px */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add subtle shadow to the sidebar */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.sidebar-logo img {
  width: 90%; /* Reduce the logo size slightly */
  margin-bottom: 20px;
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
}

.sidebar ul li {
  margin-bottom: 20px;
  background-color: #ffffff;
}

.sidebar ul li a {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #000000; /* Set text color to black */
  font-size: 13px; /* Set the font size to 13px */
  padding: 10px;
  border-radius: 8px;
  transition: background-color 0.3s ease;
  font-family: 'Rubik', sans-serif;
}

.sidebar ul li a:hover {
  background-color: #3482FF;
}

.sidebar ul li a .icon {
  margin-right: 10px;
  font-size: 24px;
}

.sidebar-bottom {
  margin-top: auto; /* Push the logout link to the bottom */
}
`, "",{"version":3,"sources":["webpack://./src/components/Sidebar.css"],"names":[],"mappings":"AAAA,6CAA6C;;AAG7C;EACE,YAAY;EACZ,yBAAyB;EACzB,aAAa;EACb,aAAa;EACb,eAAe;EACf,gCAAgC;EAChC,eAAe,EAAE,8BAA8B;EAC/C,wCAAwC,EAAE,qCAAqC;EAC/E,aAAa;EACb,sBAAsB;EACtB,8BAA8B;AAChC;;AAEA;EACE,UAAU,EAAE,kCAAkC;EAC9C,mBAAmB;AACrB;;AAEA;EACE,qBAAqB;EACrB,UAAU;AACZ;;AAEA;EACE,mBAAmB;EACnB,yBAAyB;AAC3B;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,qBAAqB;EACrB,cAAc,EAAE,4BAA4B;EAC5C,eAAe,EAAE,8BAA8B;EAC/C,aAAa;EACb,kBAAkB;EAClB,sCAAsC;EACtC,gCAAgC;AAClC;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,gBAAgB,EAAE,uCAAuC;AAC3D","sourcesContent":["/* Include the Rubik font from Google Fonts */\n@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;700&display=swap');\n\n.sidebar {\n  width: 215px;\n  background-color: #ffffff;\n  padding: 15px;\n  height: 100vh;\n  position: fixed;\n  font-family: 'Rubik', sans-serif;\n  font-size: 13px; /* Set the font size to 13px */\n  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add subtle shadow to the sidebar */\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n}\n\n.sidebar-logo img {\n  width: 90%; /* Reduce the logo size slightly */\n  margin-bottom: 20px;\n}\n\n.sidebar ul {\n  list-style-type: none;\n  padding: 0;\n}\n\n.sidebar ul li {\n  margin-bottom: 20px;\n  background-color: #ffffff;\n}\n\n.sidebar ul li a {\n  display: flex;\n  align-items: center;\n  text-decoration: none;\n  color: #000000; /* Set text color to black */\n  font-size: 13px; /* Set the font size to 13px */\n  padding: 10px;\n  border-radius: 8px;\n  transition: background-color 0.3s ease;\n  font-family: 'Rubik', sans-serif;\n}\n\n.sidebar ul li a:hover {\n  background-color: #3482FF;\n}\n\n.sidebar ul li a .icon {\n  margin-right: 10px;\n  font-size: 24px;\n}\n\n.sidebar-bottom {\n  margin-top: auto; /* Push the logout link to the bottom */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
