// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search-and-sort {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 10px;
}

.search-and-sort button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1rem;
}

.search-box {
  padding: 2px 5px;
  font-size: 1rem;
}

.small-pagination-button {
  background: none;
  border: none;
  color: #007bff;
  font-size: 1rem;
  cursor: pointer;
  margin: 0 5px;
  padding: 0;
}

.small-pagination-button:disabled {
  color: #ccc;
  cursor: not-allowed;
}

.assigned-schools-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.assign-new-school-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
}

.sort-arrow {
  cursor: pointer;
  font-size: 0.9rem;
  margin-left: 5px;
}

.search-icon {
  cursor: pointer;
  font-size: 1.2rem;
  margin-left: 10px;
}

.search-box {
  padding: 2px 5px;
  font-size: 0.9rem;
  margin-left: 5px;
}

.remove-button {
  background: none;
  border: none;
  color: red;
  cursor: pointer;
  font-size: 0.9rem;
}

`, "",{"version":3,"sources":["webpack://./src/components/EditManager.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,QAAQ;EACR,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,YAAY;EACZ,eAAe;EACf,eAAe;AACjB;;AAEA;EACE,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,gBAAgB;EAChB,YAAY;EACZ,cAAc;EACd,eAAe;EACf,eAAe;EACf,aAAa;EACb,UAAU;AACZ;;AAEA;EACE,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;EACzB,WAAW;EACX,YAAY;EACZ,iBAAiB;EACjB,eAAe;AACjB;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;EAChB,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,YAAY;EACZ,UAAU;EACV,eAAe;EACf,iBAAiB;AACnB","sourcesContent":[".search-and-sort {\n  display: flex;\n  align-items: center;\n  gap: 8px;\n  margin-bottom: 10px;\n}\n\n.search-and-sort button {\n  background: none;\n  border: none;\n  cursor: pointer;\n  font-size: 1rem;\n}\n\n.search-box {\n  padding: 2px 5px;\n  font-size: 1rem;\n}\n\n.small-pagination-button {\n  background: none;\n  border: none;\n  color: #007bff;\n  font-size: 1rem;\n  cursor: pointer;\n  margin: 0 5px;\n  padding: 0;\n}\n\n.small-pagination-button:disabled {\n  color: #ccc;\n  cursor: not-allowed;\n}\n\n.assigned-schools-header {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin-top: 20px;\n}\n\n.assign-new-school-button {\n  background-color: #007bff;\n  color: #fff;\n  border: none;\n  padding: 5px 10px;\n  cursor: pointer;\n}\n\n.sort-arrow {\n  cursor: pointer;\n  font-size: 0.9rem;\n  margin-left: 5px;\n}\n\n.search-icon {\n  cursor: pointer;\n  font-size: 1.2rem;\n  margin-left: 10px;\n}\n\n.search-box {\n  padding: 2px 5px;\n  font-size: 0.9rem;\n  margin-left: 5px;\n}\n\n.remove-button {\n  background: none;\n  border: none;\n  color: red;\n  cursor: pointer;\n  font-size: 0.9rem;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
