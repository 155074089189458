// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    text-align: center;
  }
  
  .gif-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .full-page-gif {
    max-width: 100%;
    max-height: 100%;
  }
  
  .welcome-message {
    margin-top: 20px; /* Adjust as necessary */
    transition: opacity 1s ease-in-out;
    opacity: 0;
    animation: fadeIn 1s forwards;
    animation-delay: 0.1s; /* Same as the loading time */
  }
  
  @keyframes fadeIn {
    to {
      opacity: 1;
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/components/Dashboard.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;IACb,kBAAkB;EACpB;;EAEA;IACE,aAAa;IACb,uBAAuB;IACvB,mBAAmB;EACrB;;EAEA;IACE,eAAe;IACf,gBAAgB;EAClB;;EAEA;IACE,gBAAgB,EAAE,wBAAwB;IAC1C,kCAAkC;IAClC,UAAU;IACV,6BAA6B;IAC7B,qBAAqB,EAAE,6BAA6B;EACtD;;EAEA;IACE;MACE,UAAU;IACZ;EACF","sourcesContent":[".dashboard-container {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    height: 100vh;\n    text-align: center;\n  }\n  \n  .gif-container {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n  \n  .full-page-gif {\n    max-width: 100%;\n    max-height: 100%;\n  }\n  \n  .welcome-message {\n    margin-top: 20px; /* Adjust as necessary */\n    transition: opacity 1s ease-in-out;\n    opacity: 0;\n    animation: fadeIn 1s forwards;\n    animation-delay: 0.1s; /* Same as the loading time */\n  }\n  \n  @keyframes fadeIn {\n    to {\n      opacity: 1;\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
